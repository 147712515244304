<template>
  <div>
    <template v-if="countDialog">
      <NumericKeypad
        title="Бройка:"
        @okClicked="
          editedCountOperation.quantity = $event;
          countDialog = false;
        "
        @cancelClicked="countDialog = false"
      />
    </template>
    <template v-else-if="scrapDialog">
      <ErrorMessages
        v-if="localErrorMessages"
        :error-messages="localErrorMessages"
      />
      <NumericKeypad
        key="crap-quantity"
        title="Бройка:"
        @okClicked="handleSaveScrapClick"
        @cancelClicked="
          scrapDialog = false;
          scrapForm.routeMapCostStandardId = 0;
        "
      />
    </template>
    <template v-else-if="scrapCodeDialog">
      <ErrorMessages
        v-if="localErrorMessages"
        :error-messages="localErrorMessages"
      />
      <NumericKeypad
        key="crap-confirm-code"
        title="Код за подпис на оператор:"
        @okClicked="
          scrapForm.code = $event;
          $emit('sendScrapData', { form: scrapForm, id: routemap.id });
        "
        @cancelClicked="
          scrapCodeDialog = false;
          scrapForm.routeMapCostStandardId = 0;
        "
      />
    </template>
    <template v-else-if="codeDialog">
      <NumericKeypad
        title="Код за подпис на оператор:"
        @okClicked="
          codeDialog = false;
          form.code = $event;
          $emit('operatorCheckout', form);
        "
        @cancelClicked="codeDialog = false"
      />
    </template>
    <template v-else>
      <h1 class="heading">Приключи работа по {{ routemap.name }}</h1>
      <h2 class="sub-heading">
        Оператор:
        <strong class="text-danger">
          {{ operator.firstName }}
          {{ operator.lastName }}
        </strong>
      </h2>
      <ErrorMessages
        v-if="localErrorMessages"
        :error-messages="localErrorMessages"
      />
      <hr />
      <h3 class="operations-heading">Операции:</h3>
      <template v-if="form.operations.length > 0">
        <v-row v-for="(operation, index) in form.operations" :key="index">
          <v-col cols="12" sm="6">
            <label class="my-label">Операция</label>
            <select
              v-model="operation.operationId"
              class="form-control my-input"
            >
              <option disabled v-bind:value="0">избери</option>
              <option
                v-for="op in routemap.productOperations"
                v-bind:key="op.id"
                v-bind:value="op.id"
              >
                {{ op.name }} {{ op.productName }}</option
              >
            </select>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            @click="
              editedCountOperation = operation;
              countDialog = true;
            "
          >
            <label class="my-label">Бройка</label>
            <p
              type="text"
              class="form-control my-input d-flex align-items-center disabled"
            >
              {{ operation.quantity }}
            </p>
          </v-col>
        </v-row>
        <hr />
      </template>
      <v-row>
        <v-col cols="12" sm="4">
          <span
            @click="addOperation()"
            class="btn btn-block btn-primary my-btn"
          >
            Добави нова операция
          </span>
        </v-col>
        <v-col cols="12" sm="4">
          <span
            @click="handleSaveClicked"
            class="btn btn-block btn-success my-btn"
          >
            Приключи работа
          </span>
        </v-col>
        <v-col cols="12" sm="4">
          <span
            @click="scrapDialog = true"
            class="btn btn-block btn-warning my-btn"
          >
            Брак на суровина
          </span>
        </v-col>
        <v-col cols="12" sm="6">
          <span @click="$emit('close')" class="btn btn-block btn-danger my-btn">
            Назад
          </span>
        </v-col>
        <v-col cols="12" sm="6">
          <span
            @click="
              $emit('logout', {
                operator_id: operator.id,
                route_map_id: routemap.id
              })
            "
            class="btn btn-block btn-danger my-btn"
          >
            Напусни тази маршрутна карта
          </span>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import ErrorMessages from "@/views/pages/hall/components/actions/ErrorMessages";
import NumericKeypad from "@/views/pages/hall/components/actions/NumericKeypad";

export default {
  components: {
    ErrorMessages,
    NumericKeypad
  },

  props: {
    routemap: {
      required: true,
      type: Object
    },

    operator: {
      required: true,
      type: Object
    },

    errorMessages: {
      default: () => {}
    }
  },

  data() {
    return {
      form: {
        routeMapId: this.routemap.id,
        operatorId: this.operator.id,
        code: "",
        operations: [
          {
            operationId: 0,
            quantity: 1
          }
        ]
      },
      countDialog: false,
      codeDialog: false,
      editedCountOperation: null,
      localErrorMessages: {},
      scrapCodeDialog: false,
      scrapDialog: false,
      scrapForm: {
        quantity: null,
        code: null
      }
    };
  },

  watch: {
    errorMessages() {
      this.localErrorMessages = this.errorMessages;
    }
  },

  methods: {
    handleSaveScrapClick(event) {
      this.scrapForm.quantity = event;

      if (!this.scrapForm.quantity > 0) {
        this.localErrorMessages = {
          0: ["Броят на бракувани материали трябва да бъде минимум 1"]
        };

        return;
      } else {
        this.localErrorMessages = {};
      }

      this.scrapDialog = false;
      this.scrapCodeDialog = true;
    },
    addOperation() {
      this.form.operations.push({
        operationId: 0,
        quantity: 1
      });
    },

    handleSaveClicked() {
      let invalidOperation = false;
      this.form.operations.forEach(operation => {
        if (operation.operationId < 1 || operation.quantity < 1) {
          invalidOperation = true;
        }
      });

      if (invalidOperation) {
        this.localErrorMessages = {
          0: ["Моля попълнете всички полета коректно"]
        };

        return;
      }

      this.codeDialog = true;
    }
  }
};
</script>

<style scoped>
.heading {
  font-size: 3vw;
}

.sub-heading {
  font-size: 2.5vw;
}

.operations-heading {
  font-size: 2vw;
}

.my-input {
  min-height: 60px;
  font-size: 18px;
}

.my-label {
  font-size: 16px;
}

.my-btn {
  height: 5vw;
  font-size: 2vw;
}

.disabled {
  background-color: #f0f0f0;
}
</style>
