<template>
  <div
    class="alert status-wrapper p-1 mb-2"
    :class="{
      'alert-warning': routemap.status === 4,
      'alert-success': routemap.status === 1 || routemap.status === 2,
      'alert-danger': routemap.status === 3,
      'pl-3': routemap.status === 4
    }"
  >
    <div
      class="d-flex justify-content-center w-100"
      :class="{
        'align-items-center': routemap.status !== 4,
        'flex-column': routemap.status === 4
      }"
    >
      <div class="status">{{ routemap.statusName }}</div>
      <template v-if="routemap.status === 4">
        <div class="status">
          <countdown
            :time="routemap.nextPause.secondsToFinishPause * 1000"
            @end="$emit('break-finished')"
            :transform="
              props => {
                Object.entries(props).forEach(([key, value]) => {
                  // Adds leading zero
                  const digits = value < 10 ? `0${value}` : value;
                  props[key] = `${digits}`;
                });
                return props;
              }
            "
          >
            <template slot-scope="props">
              {{ props.minutes }} мин. {{ props.seconds }} сек.
            </template>
          </countdown>
        </div>
        <div class="break-interval">
          {{ routemap.nextPause.from }} - {{ routemap.nextPause.to }} ч.
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    routemap: {
      required: true,
      type: Object
    }
  }
};
</script>

<style scoped>
.status-wrapper {
  color: white;
  height: 12vw;
}

.status {
  font-size: 3vw;
}

.break-interval {
  font-size: 1.5vw;
}
</style>
