var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert status-wrapper p-1 mb-2",class:{
    'alert-warning': _vm.routemap.status === 4,
    'alert-success': _vm.routemap.status === 1 || _vm.routemap.status === 2,
    'alert-danger': _vm.routemap.status === 3,
    'pl-3': _vm.routemap.status === 4
  }},[_c('div',{staticClass:"d-flex justify-content-center w-100",class:{
      'align-items-center': _vm.routemap.status !== 4,
      'flex-column': _vm.routemap.status === 4
    }},[_c('div',{staticClass:"status"},[_vm._v(_vm._s(_vm.routemap.statusName))]),(_vm.routemap.status === 4)?[_c('div',{staticClass:"status"},[_c('countdown',{attrs:{"time":_vm.routemap.nextPause.secondsToFinishPause * 1000,"transform":props => {
              Object.entries(props).forEach(([key, value]) => {
                // Adds leading zero
                const digits = value < 10 ? `0${value}` : value;
                props[key] = `${digits}`;
              });
              return props;
            }},on:{"end":function($event){return _vm.$emit('break-finished')}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.minutes)+" мин. "+_vm._s(props.seconds)+" сек. ")]}}],null,false,18833629)})],1),_c('div',{staticClass:"break-interval"},[_vm._v(" "+_vm._s(_vm.routemap.nextPause.from)+" - "+_vm._s(_vm.routemap.nextPause.to)+" ч. ")])]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }