<template>
  <v-col cols="12">
    <div class="form-group">
      <div class="field-wrap">
        <vu-select
          @input="changeRouteMap"
          :options="activeRouteMaps"
          :get-option-label="option => option.product.name + ' - ' + option.id"
          :reduce="routemap => routemap.id"
          :value="activeRouteMap"
        >
        </vu-select>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "ActiveRouteMapsInTheHall",
  props: ["activeRouteMaps", "activeRouteMap", "changeRouteMap"]
};
</script>

<style scoped></style>
