var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6"}},[_c('h1',{staticClass:"text-size"},[_vm._v(_vm._s(_vm.routemap.name))]),_c('div',{staticClass:"text-size"},[_vm._v("Партиден № "+_vm._s(_vm.routemap.product.batch))]),_vm._l((_vm.executedOperations),function(operation){return _c('div',{key:operation.id,staticClass:"text-size routemap-quantity",on:{"click":function($event){return _vm.$emit('showPerformedOperationsClicked')}}},[_vm._v(" "+_vm._s(operation.operation)+": "+_vm._s(operation.quantity)+"/"+_vm._s(_vm.routemap.totalQuantity - operation.quantity)+" ")])}),_c('div',{staticClass:"text-size"},[_vm._v(" Брак на суровина: "+_vm._s(_vm.routemap.scrapQuantity)+" ")]),_c('div',{staticClass:"text-size"},[_vm._v(" "+_vm._s(_vm.routemap.productManager)+" ")]),_c('div',{staticClass:"text-size"},[_vm._v(" Срок на годност: "+_vm._s(_vm.routemap.product.expirationDate)+" ")]),_c('div',[_c('span',{staticClass:"start-label"},[_vm._v(" Старт: ")]),_c('span',{staticClass:"start-date"},[_vm._v(" "+_vm._s(_vm.startDate)+" ")])])],2),_c('v-col',{staticClass:"pl-0 pb-0",attrs:{"cols":"12","sm":"6"}},[_c('v-row',[_c('v-col',{staticClass:"p-0 pr-2",attrs:{"cols":"8"}},[_c('Status',{attrs:{"routemap":_vm.routemap}})],1),_c('v-col',{staticClass:"p-0",attrs:{"cols":"4"}},[_c('div',{staticClass:"alert alert-hall d-flex justify-content-center align-items-center mb-2"},[_c('span',{staticClass:"hall"},[_vm._v(_vm._s(_vm.user.hallName))])])])],1),_c('v-row',[_c('v-col',{staticClass:"p-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"alert alert-control d-flex justify-content-center mb-0",class:{
            'alert-danger':
              !_vm.routemap.hallsIsCleaned ||
              !_vm.routemap.isCharging ||
              (_vm.routemap.deniedControl &&
                _vm.routemap.deniedControlSentToLabourer) ||
              (_vm.currentTimeIsBetweenWorkingTime &&
                !_vm.routemap.minutesToNextControl),
            'alert-success':
              _vm.currentTimeIsBetweenWorkingTime && _vm.routemap.minutesToNextControl
          }},[(!_vm.routemap.hallsIsCleaned)?_c('div',{staticClass:"control-message"},[_vm._v(" Залата е отбелязана за почистване. ")]):(!_vm.routemap.isCharging)?_c('div',{staticClass:"control-message"},[_vm._v(" Залата не е заредена със суровини и материали. Зареждането е планувано за "+_vm._s(_vm._f("formatDateTime")(_vm.routemap.materialDate))+" г. ")]):(
              _vm.routemap.deniedControl && _vm.routemap.deniedControlSentToLabourer
            )?_c('div',{staticClass:"control-message"},[_vm._v(" Залата е отбелязана за повторно зареждане. ")]):(
              _vm.currentTimeIsBetweenWorkingTime &&
                !_vm.routemap.minutesToNextControl
            )?_c('div',{staticClass:"control-message"},[_vm._v(" Моля извършете контрол на качеството! ")]):(_vm.currentTimeIsBetweenWorkingTime)?[_c('div',{staticClass:"d-flex mr-2"},[_c('span',{staticClass:"control-label"},[_vm._v("Контрол")])]),_c('div',{staticClass:"control-time d-flex"},[_c('countdown',{attrs:{"time":_vm.routemap.secondsToNextControl * 1000,"auto-start":!_vm.routeMapIsPaused && !_vm.routeMapIsNew && !_vm.routeMapIsInBreak,"transform":props => {
                    Object.entries(props).forEach(([key, value]) => {
                      // Adds leading zero
                      const digits = value < 10 ? `0${value}` : value;
                      props[key] = `${digits}`;
                    });
                    return props;
                  }},on:{"end":function($event){return _vm.$emit('controlTime')}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.hours)+" ч. "+_vm._s(props.minutes)+" мин.")]}}])})],1)]:_vm._e()],2)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }