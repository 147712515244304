<template>
  <div>
    <v-row>
      <v-col class="" cols="12" sm="12" md="6">
        <h1 class="text-center" v-if="title">{{ title }}</h1>
        <v-text-field
          ref="keypadNumber"
          class="centered-input"
          v-model="keypadNumber"
          @input="$emit('input', $event)"
          filled
          disabled
          autofocus
          :type="inputType"
        ></v-text-field>
        <v-row>
          <v-col cols="6" class="pt-0 pb-0 pl-0">
            <v-btn
              class="btn-block"
              dark
              x-large
              color="rgb(37, 189, 173)"
              @click="okClicked()"
            >
              OK
            </v-btn>
          </v-col>
          <v-col cols="6" class="pt-0 pr-0 pb-0">
            <v-btn
              class="btn-block"
              dark
              x-large
              color="error"
              @click="cancelClicked()"
            >
              Отказ
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="mx-auto" cols="12" sm="12" md="6">
        <div class="mx-auto keypad">
          <v-row>
            <v-col class="text-center pt-0" cols="4">
              <v-btn
                dark
                x-large
                color="rgb(37, 189, 173)"
                @click="addNumber(1)"
              >
                1
              </v-btn>
            </v-col>
            <v-col class="text-center pt-0" cols="4">
              <v-btn
                dark
                x-large
                color="rgb(37, 189, 173)"
                @click="addNumber(2)"
              >
                2
              </v-btn>
            </v-col>
            <v-col class="text-center pt-0" cols="4">
              <v-btn
                dark
                x-large
                color="rgb(37, 189, 173)"
                @click="addNumber(3)"
              >
                3
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center pt-0" cols="4">
              <v-btn
                dark
                x-large
                color="rgb(37, 189, 173)"
                @click="addNumber(4)"
              >
                4
              </v-btn>
            </v-col>
            <v-col class="text-center pt-0" cols="4">
              <v-btn
                dark
                x-large
                color="rgb(37, 189, 173)"
                @click="addNumber(5)"
              >
                5
              </v-btn>
            </v-col>
            <v-col class="text-center pt-0" cols="4">
              <v-btn
                dark
                x-large
                color="rgb(37, 189, 173)"
                @click="addNumber(6)"
              >
                6
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center pt-0" cols="4">
              <v-btn
                dark
                x-large
                color="rgb(37, 189, 173)"
                @click="addNumber(7)"
              >
                7
              </v-btn>
            </v-col>
            <v-col class="text-center pt-0" cols="4">
              <v-btn
                dark
                x-large
                color="rgb(37, 189, 173)"
                @click="addNumber(8)"
              >
                8
              </v-btn>
            </v-col>
            <v-col class="text-center pt-0" cols="4">
              <v-btn
                dark
                x-large
                color="rgb(37, 189, 173)"
                @click="addNumber(9)"
              >
                9
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center pt-0" cols="4">
              <v-btn x-large color="error" @click="clear()">
                C
              </v-btn>
            </v-col>
            <v-col class="text-center pt-0" cols="4">
              <v-btn
                dark
                x-large
                color="rgb(37, 189, 173)"
                @click="addNumber(0)"
              >
                0
              </v-btn>
            </v-col>
            <v-col class="text-center pt-0" cols="4">
              <v-btn x-large color="warning" @click="backspace()">
                <v-icon>fas fa-backspace</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row class="keypad mx-auto"> </v-row>
  </div>
</template>
<style lang="css" scoped>
.v-btn.v-size--x-large {
  font-size: 3rem;
  padding: 50px;
}
.v-btn:not(.v-btn--round).v-size--x-large {
  height: 82px;
}
.v-text-field >>> input {
  font-size: 40px;
  text-align: center;
}
.keypad {
  max-width: 435px;
}
</style>
<script>
export default {
  name: "NumericKeypad",
  inheritAttrs: false,
  props: {
    value: {
      required: false,
      default: ""
    },

    inputType: {
      required: false,
      default: "number"
    },

    title: {
      required: false
    }
  },
  data() {
    return {
      keypadNumber: this.value !== null ? this.value : ""
    };
  },
  mounted() {
    // let vm = this;
    // vm.$nextTick(function() {
    //   vm.$refs.keypadNumber.focus();
    // });
  },
  computed: {},
  methods: {
    addNumber(number) {
      let vm = this;
      vm.keypadNumber = vm.keypadNumber.concat(number);
      vm.autoFocus();
      this.$emit("input", this.keypadNumber);
    },
    backspace() {
      let vm = this;
      vm.keypadNumber = vm.keypadNumber.slice(0, -1);
      vm.autoFocus();
      this.$emit("input", this.keypadNumber);
    },
    clear() {
      let vm = this;
      vm.keypadNumber = "";
      vm.autoFocus();
      this.$emit("input", this.keypadNumber);
    },
    okClicked() {
      let vm = this;
      this.$emit("okClicked", this.keypadNumber);
      vm.autoFocus();
    },
    cancelClicked() {
      this.$emit("cancelClicked");
    },
    autoFocus() {
      let vm = this;
      vm.$nextTick(function() {
        if (vm.$refs.keypadNumber) {
          vm.$refs.keypadNumber.focus();
        }
      });
    }
  }
};
</script>
