<template>
  <v-row>
    <v-col v-for="operator in operators" :key="operator.id" cols="4">
      <v-btn
        color="#d3d3d3"
        class="my-btn operator-btn"
        x-large
        @click="$emit('operatorCheckoutClicked', operator)"
      >
        <v-icon class="my-icon mr-3"> flaticon2-user</v-icon>
        {{ operator.firstName }} {{ operator.lastName }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    operators: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped>
.my-btn {
  width: 100%;
  height: 7vw;
  min-height: 7vw;
  font-size: 2vw;
  text-transform: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.my-icon {
  font-size: 2.5vw;
}
</style>
