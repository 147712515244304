var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.countDialog)?[_c('NumericKeypad',{attrs:{"title":"Бройка:"},on:{"okClicked":function($event){_vm.editedCountOperation.quantity = $event;
        _vm.countDialog = false;},"cancelClicked":function($event){_vm.countDialog = false}}})]:(_vm.scrapDialog)?[(_vm.localErrorMessages)?_c('ErrorMessages',{attrs:{"error-messages":_vm.localErrorMessages}}):_vm._e(),_c('NumericKeypad',{key:"crap-quantity",attrs:{"title":"Бройка:"},on:{"okClicked":_vm.handleSaveScrapClick,"cancelClicked":function($event){_vm.scrapDialog = false;
        _vm.scrapForm.routeMapCostStandardId = 0;}}})]:(_vm.scrapCodeDialog)?[(_vm.localErrorMessages)?_c('ErrorMessages',{attrs:{"error-messages":_vm.localErrorMessages}}):_vm._e(),_c('NumericKeypad',{key:"crap-confirm-code",attrs:{"title":"Код за подпис на оператор:"},on:{"okClicked":function($event){_vm.scrapForm.code = $event;
        _vm.$emit('sendScrapData', { form: _vm.scrapForm, id: _vm.routemap.id });},"cancelClicked":function($event){_vm.scrapCodeDialog = false;
        _vm.scrapForm.routeMapCostStandardId = 0;}}})]:(_vm.codeDialog)?[_c('NumericKeypad',{attrs:{"title":"Код за подпис на оператор:"},on:{"okClicked":function($event){_vm.codeDialog = false;
        _vm.form.code = $event;
        _vm.$emit('operatorCheckout', _vm.form);},"cancelClicked":function($event){_vm.codeDialog = false}}})]:[_c('h1',{staticClass:"heading"},[_vm._v("Приключи работа по "+_vm._s(_vm.routemap.name))]),_c('h2',{staticClass:"sub-heading"},[_vm._v(" Оператор: "),_c('strong',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.operator.firstName)+" "+_vm._s(_vm.operator.lastName)+" ")])]),(_vm.localErrorMessages)?_c('ErrorMessages',{attrs:{"error-messages":_vm.localErrorMessages}}):_vm._e(),_c('hr'),_c('h3',{staticClass:"operations-heading"},[_vm._v("Операции:")]),(_vm.form.operations.length > 0)?[_vm._l((_vm.form.operations),function(operation,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',{staticClass:"my-label"},[_vm._v("Операция")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(operation.operationId),expression:"operation.operationId"}],staticClass:"form-control my-input",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(operation, "operationId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":0}},[_vm._v("избери")]),_vm._l((_vm.routemap.productOperations),function(op){return _c('option',{key:op.id,domProps:{"value":op.id}},[_vm._v(" "+_vm._s(op.name)+" "+_vm._s(op.productName))])})],2)]),_c('v-col',{attrs:{"cols":"12","sm":"6"},on:{"click":function($event){_vm.editedCountOperation = operation;
            _vm.countDialog = true;}}},[_c('label',{staticClass:"my-label"},[_vm._v("Бройка")]),_c('p',{staticClass:"form-control my-input d-flex align-items-center disabled",attrs:{"type":"text"}},[_vm._v(" "+_vm._s(operation.quantity)+" ")])])],1)}),_c('hr')]:_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('span',{staticClass:"btn btn-block btn-primary my-btn",on:{"click":function($event){return _vm.addOperation()}}},[_vm._v(" Добави нова операция ")])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('span',{staticClass:"btn btn-block btn-success my-btn",on:{"click":_vm.handleSaveClicked}},[_vm._v(" Приключи работа ")])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('span',{staticClass:"btn btn-block btn-warning my-btn",on:{"click":function($event){_vm.scrapDialog = true}}},[_vm._v(" Брак на суровина ")])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"btn btn-block btn-danger my-btn",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Назад ")])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"btn btn-block btn-danger my-btn",on:{"click":function($event){return _vm.$emit('logout', {
              operator_id: _vm.operator.id,
              route_map_id: _vm.routemap.id
            })}}},[_vm._v(" Напусни тази маршрутна карта ")])])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }