<template>
  <div>
    <template v-if="!loading">
      <Header @refreshClicked="fetchActiveRouteMaps" />
      <template v-if="routemap">
        <ActiveRouteMapsInTheHall
          :active-route-maps="activeRouteMaps"
          :active-route-map="activeRouteMap"
          :changeRouteMap="changeRouteMap"
        />
        <PerformedOperationsDialog
          v-if="performedOperationsDialog"
          :routemap="routemap"
          :user="user"
          @close="performedOperationsDialog = false"
        />
        <ControlCodeDialog
          v-else-if="controlCodeDialog"
          :code="controlCode"
          @close="controlCodeDialog = false"
        />
        <OperatorCheckDialog
          v-else-if="operatorCheckDialog"
          @operatorCodeAdded="handleOperatorCodeAdded"
          @operatorDialogCanceled="operatorCheckDialog = false"
        />
        <OperatorCheckoutDialog
          v-else-if="operatorCheckoutDialog"
          :routemap="routemap"
          :operator="checkoutedOperator"
          :errorMessages="errorMessages"
          @operatorCheckout="processOperatorCheckoutForm"
          @sendScrapData="sendScrapData"
          @close="operatorCheckoutDialog = false"
          @logout="forceLogout($event)"
        />
        <template v-else>
          <InfoSection
            :routemap="routemap"
            :user="user"
            @controlTime="fetchRoutemap"
            @showPerformedOperationsClicked="performedOperationsDialog = true"
          />
          <ErrorMessages v-if="errorMessages" :error-messages="errorMessages" />
          <ControlsSection
            @addOperatorClicked="operatorCheckDialog = true"
            @controlCodeClicked="getControlCode"
          />
          <OperatorsSection
            :operators="
              _.filter(routemap.operators, {
                active: true
              })
            "
            @operatorCheckoutClicked="
              checkoutedOperator = $event;
              operatorCheckoutDialog = true;
              errorMessages = false;
            "
          />
        </template>
      </template>
      <h1 v-else class="text-center">
        Няма активна маршрутна карта в тази зала
      </h1>
    </template>
    <v-row v-else class="full-height" align-content="center">
      <v-col cols="12">
        <v-progress-linear
          class="mt-5"
          color="#25bdad"
          buffer-value="0"
          stream
          reverse
        >
        </v-progress-linear>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  GET_ROUTEMAP_INFO,
  GET_ACTIVE_ROUTEMAPS,
  RAW_PRODUCT_SCRAP
} from "@/store/routemaps.module";

import _ from "lodash";

import { GET_ROUTEMAPS_CONTROL_CODE } from "@/store/routemapsControls.module";
import {
  OPERATOR_LOGIN,
  OPERATOR_LOGOUT,
  OPERATOR_FORCE_LOGOUT
} from "@/store/operators.module";
import Header from "@/views/pages/hall/components/actions/Header";
import InfoSection from "@/views/pages/hall/components/actions/InfoSection";
import ErrorMessages from "@/views/pages/hall/components/actions/ErrorMessages";
import ControlsSection from "@/views/pages/hall/components/actions/ControlsSection";
import OperatorsSection from "@/views/pages/hall/components/actions/OperatorsSection";
import OperatorCheckDialog from "@/views/pages/hall/components/actions/OperatorCheckDialog";
import OperatorCheckoutDialog from "@/views/pages/hall/components/actions/OperatorCheckoutDialog";
import ControlCodeDialog from "@/views/pages/hall/components/actions/ControlCodeDialog";
import PerformedOperationsDialog from "@/views/pages/hall/components/actions/PerformedOperationsDialog";
import ActiveRouteMapsInTheHall from "@/views/pages/hall/components/ActiveRouteMapsInTheHall";

export default {
  components: {
    ActiveRouteMapsInTheHall,
    Header,
    InfoSection,
    ErrorMessages,
    ControlsSection,
    OperatorsSection,
    OperatorCheckDialog,
    OperatorCheckoutDialog,
    ControlCodeDialog,
    PerformedOperationsDialog
  },

  data() {
    return {
      activeRouteMap: null,
      activeRouteMaps: [],
      user: JSON.parse(window.localStorage.getItem("userData")),
      routemap: null,
      chartData: [
        ["Бройки", "Бройки"],
        ["оставащи", 0],
        ["направени", 0]
      ],
      chartOptions: {
        chart: {},
        slices: {
          0: {
            color: "red"
          },
          1: {
            color: "#3ebeaf"
          }
        },
        pieSliceText: "value"
      },
      loading: true,
      errorMessages: [],
      timer: null,
      performedOperationsDialog: false,
      operatorCheckDialog: false,
      operatorCheckoutDialog: false,
      controlCodeDialog: false,
      checkoutedOperator: null,
      controlCode: null,
      pauseTimeOut: null
    };
  },

  mounted() {
    let vm = this;

    vm.fetchActiveRouteMaps();

    vm.timer = setInterval(() => {
      vm.fetchActiveRouteMaps();
    }, 30 * 1000);
  },

  methods: {
    sendScrapData(form) {
      let vm = this;

      vm.$store
        .dispatch(RAW_PRODUCT_SCRAP, form)
        .then(() => {
          vm.$notification["success"]({
            message: "Успешна операция"
          });

          vm.operatorCheckoutDialog = false;

          vm.fetchRoutemap();
        })
        .catch(response => {
          if (response.data.errors) {
            vm.errorMessages = response.data.errors;

            vm.$scrollTo("html", 420, {
              offset: -300
            });
          } else {
            vm.$notification["error"]({
              message: "Нещо се обърка"
            });
          }
        });
    },
    forceLogout(operatorId) {
      let vm = this;

      vm.$store
        .dispatch(OPERATOR_FORCE_LOGOUT, operatorId)
        .then(() => {
          vm.$notification["success"]({
            message: "Успешна операция"
          });

          vm.operatorCheckoutDialog = false;

          vm.fetchRoutemap();
        })
        .catch(response => {
          if (response.data.errors) {
            vm.errorMessages = response.data.errors;

            vm.$scrollTo("html", 420, {
              offset: -300
            });
          } else {
            vm.$notification["error"]({
              message: "Нещо се обърка"
            });
          }
        });
    },
    changeRouteMap(id) {
      this.activeRouteMap = id;

      this.fetchRoutemap();

      this.operatorCheckoutDialog = false;
      this.controlCodeDialog = false;
    },
    fetchActiveRouteMaps() {
      let vm = this;

      vm.$store
        .dispatch(GET_ACTIVE_ROUTEMAPS, vm.user.hall_id)
        .then(data => {
          vm.activeRouteMaps = data.data;
          if (vm.activeRouteMaps.length > 0) {
            vm.activeRouteMap = _.find(
              vm.activeRouteMaps,
              routeMap => routeMap.id === vm.activeRouteMap
            )?.id;

            if (!vm.activeRouteMap) {
              vm.activeRouteMap = _.first(vm.activeRouteMaps).id;
            }

            vm.fetchRoutemap();
          }
        })
        .finally(() => {
          vm.loading = false;
        });
    },
    fetchRoutemap(breakInterval = true) {
      let vm = this;

      vm.errorMessages = null;

      vm.$store
        .dispatch(GET_ROUTEMAP_INFO, vm.activeRouteMap)
        .then(data => {
          vm.routemap = data.data;

          // Breaks fetch logic
          clearInterval(vm.pauseTimeOut);
          if (
            breakInterval &&
            vm.routemap.nextPause &&
            vm.routemap.nextPause.secondsToStartPause > 0
          ) {
            vm.pauseTimeOut = setTimeout(() => {
              vm.fetchRoutemap(false);
            }, (vm.routemap.nextPause.secondsToStartPause + 2) * 1000);
          }

          vm.chartData = [
            ["Бройки", "Бройки"],
            [
              "оставащи",
              vm.routemap.remainQuantity >= 0 ? vm.routemap.remainQuantity : 0
            ],
            ["направени", vm.routemap.readyQuantity]
          ];
        })
        .finally(() => {
          vm.loading = false;
        });
    },

    getControlCode() {
      let vm = this;

      vm.errorMessages = null;

      vm.$store
        .dispatch(GET_ROUTEMAPS_CONTROL_CODE, vm.routemap.id)
        .then(data => {
          vm.controlCode = data.data.code;

          vm.controlCodeDialog = true;
        })
        .finally(() => {
          vm.loading = false;
        });
    },

    handleOperatorCodeAdded(code) {
      let vm = this;

      vm.errorMessages = [];

      vm.$store
        .dispatch(OPERATOR_LOGIN, {
          routeMapId: vm.routemap.id,
          code: code
        })
        .then(() => {
          vm.$notification["success"]({
            message: "Успешна операция"
          });

          vm.fetchRoutemap();
        })
        .catch(response => {
          if (response.data.errors) {
            vm.errorMessages = response.data.errors;

            vm.$scrollTo("html", 420, {
              offset: -300
            });
          } else {
            vm.$notification["error"]({
              message: "Грешен код на оператор"
            });
          }
        })
        .finally(() => {
          vm.operatorCheckDialog = false;
        });
    },

    processOperatorCheckoutForm(form) {
      let vm = this;

      vm.errorMessages = [];

      vm.$store
        .dispatch(OPERATOR_LOGOUT, form)
        .then(() => {
          vm.$notification["success"]({
            message: "Успешна операция"
          });

          vm.operatorCheckoutDialog = false;

          vm.fetchRoutemap();
        })
        .catch(response => {
          if (response.data.errors) {
            vm.errorMessages = response.data.errors;

            vm.$scrollTo("html", 420, {
              offset: -300
            });
          } else {
            vm.$notification["error"]({
              message: "Нещо се обърка"
            });
          }
        });
    }
  },

  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style scoped>
.full-height {
  height: 95vh;
}
</style>
