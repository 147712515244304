<template>
  <v-row class="mb-2 mt-4">
    <v-col cols="6">
      <v-btn
        color="#0abb87"
        class="my-btn operator-btn"
        x-large
        @click="$emit('addOperatorClicked')"
      >
        <v-icon class="my-icon mr-3">flaticon2-add-1</v-icon>
        Добави оператор
      </v-btn>
    </v-col>
    <v-col cols="6" class="pl-0">
      <v-btn
        color="warning"
        class="my-btn operator-btn"
        x-large
        @click="$emit('controlCodeClicked')"
      >
        <v-icon class="my-icon mr-3">fas fa-user-cog</v-icon>
        Код за контрол
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style scoped>
.my-btn {
  width: 100%;
  height: 7vw;
  min-height: 7vw;
  font-size: 3vw;
  font-weight: bold;
  text-transform: none;
}

.my-icon {
  font-size: 2.5vw;
}
</style>
