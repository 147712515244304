<template>
  <div>
    <h2 class="text-center">Код за контрол:</h2>
    <h1 class="text-center control-code mt-5">{{ code }}</h1>
    <div class="d-flex align-items-center mt-5">
      <v-btn
        color="rgb(37, 189, 173)"
        class="my-btn mx-auto"
        x-large
        @click="$emit('close')"
      >
        OK
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    code: {
      required: true
    }
  }
};
</script>

<style scoped>
.my-btn {
  width: 17vw;
  height: 7vw;
  min-height: 7vw;
  font-size: 3vw;
  color: white;
  font-weight: bold;
  text-transform: none;
}

.control-code {
  font-size: 3vw;
}
</style>
