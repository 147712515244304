<template>
  <div class="mb-5">
    <h4 class="mt-3 text-center operations-heading">
      Извършени операции:
    </h4>

    <v-row class="t-head table-text-size">
      <v-col>Дата</v-col>
      <v-col>Операция</v-col>
      <v-col>Бройка</v-col>
      <v-col>Оператор</v-col>
    </v-row>
    <template v-for="operation in routemap.operations">
      <v-row
        class="row border-bottom table-text-size"
        v-bind:key="'oo' + operation.id"
      >
        <v-col class="t-row">{{ operation.date }}</v-col>
        <v-col class="t-row">{{ operation.operation }}</v-col>
        <v-col class="t-row">{{ operation.quantity }}</v-col>
        <v-col class="t-row"
          >{{ operation.operator }}
          <i v-if="operation.edited_by > 0">коригирана</i>
        </v-col>
      </v-row>
    </template>
    <div class="d-flex align-items-center justify-content-center mt-5">
      <span @click="$emit('close')" class="btn btn-block btn-danger my-btn">
        Назад
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PerformedOperationsSection",
  props: {
    routemap: {
      required: true
    },
    user: {
      required: true,
      type: Object
    }
  }
};
</script>

<style scoped>
.operations-heading {
  font-size: 2.5vw;
}

.table-text-size {
  font-size: 1.5vw;
}

.my-btn {
  width: 17vw;
  height: 5vw;
  font-size: 2vw;
}
</style>
