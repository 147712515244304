<template>
  <div>
    <NumericKeypad
      title="Код на оператор:"
      @okClicked="$emit('operatorCodeAdded', $event)"
      @cancelClicked="$emit('operatorDialogCanceled')"
    />
  </div>
</template>

<script>
import NumericKeypad from "@/views/pages/hall/components/actions/NumericKeypad";

export default {
  components: {
    NumericKeypad
  },

  data() {
    return {
      //
    };
  }
};
</script>

<style scoped></style>
