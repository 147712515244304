<template>
  <v-row>
    <v-col cols="12" sm="6" class="pb-0">
      <h1 class="text-size">{{ routemap.name }}</h1>
      <div class="text-size">Партиден № {{ routemap.product.batch }}</div>
      <div
        v-for="operation in executedOperations"
        :key="operation.id"
        @click="$emit('showPerformedOperationsClicked')"
        class="text-size routemap-quantity"
      >
        {{ operation.operation }}: {{ operation.quantity }}/{{
          routemap.totalQuantity - operation.quantity
        }}
      </div>
      <div class="text-size">
        Брак на суровина: {{ routemap.scrapQuantity }}
      </div>
      <div class="text-size">
        {{ routemap.productManager }}
      </div>
      <div class="text-size">
        Срок на годност: {{ routemap.product.expirationDate }}
      </div>
      <div>
        <span class="start-label">
          Старт:
        </span>
        <span class="start-date">
          {{ startDate }}
        </span>
      </div>
    </v-col>
    <v-col cols="12" sm="6" class="pl-0 pb-0">
      <v-row>
        <v-col cols="8" class="p-0 pr-2">
          <Status :routemap="routemap" />
        </v-col>
        <v-col cols="4" class="p-0">
          <div
            class="alert alert-hall d-flex justify-content-center align-items-center mb-2"
          >
            <span class="hall">{{ user.hallName }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="p-0">
          <div
            class="alert alert-control d-flex justify-content-center mb-0"
            :class="{
              'alert-danger':
                !routemap.hallsIsCleaned ||
                !routemap.isCharging ||
                (routemap.deniedControl &&
                  routemap.deniedControlSentToLabourer) ||
                (currentTimeIsBetweenWorkingTime &&
                  !routemap.minutesToNextControl),
              'alert-success':
                currentTimeIsBetweenWorkingTime && routemap.minutesToNextControl
            }"
          >
            <div v-if="!routemap.hallsIsCleaned" class="control-message">
              Залата е отбелязана за почистване.
            </div>

            <div v-else-if="!routemap.isCharging" class="control-message">
              Залата не е заредена със суровини и материали. Зареждането е
              планувано за
              {{ routemap.materialDate | formatDateTime }} г.
            </div>
            <div
              v-else-if="
                routemap.deniedControl && routemap.deniedControlSentToLabourer
              "
              class="control-message"
            >
              Залата е отбелязана за повторно зареждане.
            </div>
            <div
              v-else-if="
                currentTimeIsBetweenWorkingTime &&
                  !routemap.minutesToNextControl
              "
              class="control-message"
            >
              Моля извършете контрол на качеството!
            </div>
            <template v-else-if="currentTimeIsBetweenWorkingTime">
              <div class="d-flex mr-2">
                <span class="control-label">Контрол</span>
              </div>
              <div class="control-time d-flex">
                <countdown
                  @end="$emit('controlTime')"
                  :time="routemap.secondsToNextControl * 1000"
                  :auto-start="
                    !routeMapIsPaused && !routeMapIsNew && !routeMapIsInBreak
                  "
                  :transform="
                    props => {
                      Object.entries(props).forEach(([key, value]) => {
                        // Adds leading zero
                        const digits = value < 10 ? `0${value}` : value;
                        props[key] = `${digits}`;
                      });
                      return props;
                    }
                  "
                >
                  <template slot-scope="props">
                    {{ props.hours }} ч. {{ props.minutes }} мин.</template
                  >
                </countdown>
              </div>
            </template>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import Status from "@/views/pages/hall/components/actions/Status";

export default {
  components: {
    Status
  },

  props: {
    routemap: {
      type: Object,
      required: true
    },

    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      //
    };
  },

  computed: {
    routeMapIsPaused() {
      return this.routemap.status == 3;
    },
    routeMapIsNew() {
      return this.routemap.status == 1;
    },
    routeMapIsInBreak() {
      return this.routemap.status == 4;
    },
    executedOperations() {
      let result = [];
      let operations = [];

      for (const productOperation of this.routemap.productOperations) {
        operations.push({ operation: productOperation.name, quantity: 0 });
      }

      operations = operations.concat([...this.routemap.operations]);

      operations.reduce(function(res, operation) {
        let key = operation.operation;

        if (!res[key]) {
          res[key] = { ...operation, quantity: 0 };
          result.push(res[key]);
        }

        res[key].quantity += operation.quantity;

        return res;
      }, {});

      return result;
    },
    currentTimeIsBetweenWorkingTime() {
      let startTime = moment(this.routemap.workingDateStartTime);
      let endTime = moment(this.routemap.workingDateEndTime);
      let currentTime = moment();

      return currentTime.isAfter(startTime) && currentTime.isBefore(endTime);
    },
    startDate() {
      return (
        moment(this.routemap.materialDate).format("HH:mm") +
        " ч. " +
        moment(this.routemap.materialDate).format("DD.MM.YYYY")
      );
    }
  }
};
</script>

<style scoped>
.text-size {
  font-size: 2.5vw;
  font-weight: bold;
}

.routemap-quantity {
  text-decoration: underline;
}

.routemap-quantity:first-letter {
  text-transform: capitalize;
}

.start-label {
  font-weight: bold;
  font-size: 2vw;
}

.start-date {
  font-weight: bold;
  font-size: 2.5vw;
}

.alert-hall {
  color: black;
  background-color: rgb(0, 204, 255);
  height: 12vw;
  display: block;
}

.hall {
  font-size: 3vw;
}

.control-message {
  font-size: 2vw;
}

.control-label {
  font-size: 1.5vw;
  align-self: flex-end;
  line-height: 2.3;
}

.control-time {
  font-size: 3vw;
}
</style>
