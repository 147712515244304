<template>
  <div
    v-if="errorMessages && Object.keys(errorMessages).length > 0"
    class="alert alert-danger mt-4"
  >
    <ul>
      <template v-for="(error, k) in errorMessages">
        <span v-bind:key="k">
          <li v-for="(message, i) in error" v-bind:key="i">
            {{ message }}
          </li>
        </span>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    errorMessages: {
      required: true
    }
  }
};
</script>

<style scoped>
ul {
  list-style-type: none;
  margin-bottom: 0;
  font-size: 2vw;
}
</style>
