<template>
  <v-row>
    <v-col cols="12" sm="4" md="6" class="p-0">
      <img :src="getLogo()" class="logo" alt="logo" />
    </v-col>
    <v-col cols="12" sm="8" md="6" class="p-0">
      <div class="d-flex justify-content-between align-items-center">
        <v-btn
          color="#25bdad"
          class="refresh-btn"
          x-large
          @click="$emit('refreshClicked')"
        >
          <v-icon color="white">fas fa-sync fa-fw</v-icon>
        </v-btn>
        <h4 class="d-flex align-items-center mb-0 date">{{ today }}</h4>
        <h2 class="d-flex align-items-center mb-0 time">{{ time }}</h2>
        <v-btn icon color="red" x-large @click="logout">
          <v-icon>flaticon-logout</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import { LOGOUT } from "@/store/auth.module";

export default {
  data() {
    return {
      time: moment().format("HH:mm:ss"),
      today: moment().format("DD/MM/YYYY")
    };
  },

  mounted() {
    setInterval(this.renderClock, 1000);
  },

  methods: {
    renderClock() {
      this.time = moment().format("HH:mm:ss");
    },

    getLogo() {
      return process.env.BASE_URL + "assets/media/logos/logo-small.png";
    },

    logout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  }
};
</script>

<style scoped>
.logo {
  width: 20vw;
}

.refresh-btn {
  width: 52px !important;
  max-width: 52px !important;
  min-width: 52px !important;
}

.date {
  font-size: 3vw;
}

.time {
  font-size: 4vw;
}
</style>
